import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button } from "react-bootstrap";
import WindowsLogo from "../imgs/windows.png";
import AppleLogo from "../imgs/apple.png";
import UbuntuLogo from "../imgs/ubuntu.png";
import AppleM1 from "../imgs/applem1.png";
import TriviaWallet from "../wallet/tvccoin.tar.gz";
import TriviaWalletWindow from "../wallet/triviacoin-1.0.0-win64-setup.zip";
import "../App.css";

function Download() {
  const [heightDeviceScreen, setHeightDeviceScreen] = useState();
  const getScreenHeight = () => {
    const heightDevice = window.screen.height;
    setHeightDeviceScreen(heightDevice + "px");
  };
  useEffect(() => {
    getScreenHeight();
  }, []);

  return (
    <div className="app-body" style={{ minHeight: heightDeviceScreen }}>
      <div className="titles-download">
        <h2 className="title-page">WELCOME TO TRIVIA COIN WALLET</h2>
        <h6 className="title-page">
          The open source wallet for trivia, built to grow with the litecoin
          network.
        </h6>
      </div>
      <Row xs={1} lg={1} className="cards-download g-4">
        <Col>
          <a className="download-link" href={TriviaWalletWindow} download>
            <Card className="card-box">
              <Card.Img variant="top" className="card-img" src={WindowsLogo} />
              <Card.Body>
                <Card.Text>Windows.</Card.Text>
                <Button
                  href={TriviaWalletWindow}
                  className="btn-slideshow"
                  size="lg"
                  variant="outline-light"
                >
                  DOWNLOAD
                </Button>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col>
          <a className="download-link" href={TriviaWallet} download>
            <Card className="card-box">
              <Card.Img variant="top" className="card-img" src={AppleLogo} />
              <Card.Body>
                <Card.Text>Apple with Intel.</Card.Text>
                <Button
                  href={TriviaWallet}
                  className="btn-slideshow"
                  size="lg"
                  variant="outline-light"
                >
                  DOWNLOAD
                </Button>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col>
          <a className="download-link" href={TriviaWallet} download>
            <Card className="card-box">
              <Card.Img variant="top" className="card-img-smll" src={AppleM1} />
              <Card.Body>
                <Card.Text>Apple with M1.</Card.Text>
                <Button
                  href={TriviaWallet}
                  className="btn-slideshow"
                  size="lg"
                  variant="outline-light"
                >
                  DOWNLOAD
                </Button>
              </Card.Body>
            </Card>
          </a>
        </Col>
        <Col>
          <a className="download-link" href={TriviaWallet} download>
            <Card className="card-box">
              <Card.Img
                variant="top"
                className="card-img-smll"
                src={UbuntuLogo}
              />
              <Card.Body>
                <Card.Text>Ubuntu.</Card.Text>
                <Button
                  href={TriviaWallet}
                  className="btn-slideshow"
                  size="lg"
                  variant="outline-light"
                >
                  DOWNLOAD
                </Button>
              </Card.Body>
            </Card>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default Download;
