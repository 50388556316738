import React, { useState, useEffect } from "react";
import "../App.css";

function Info() {
  const [heightDeviceScreen, setHeightDeviceScreen] = useState();
  const getScreenHeight = () => {
    const heightDevice = window.screen.height;
    setHeightDeviceScreen(heightDevice + "px");
  };
  useEffect(() => {
    getScreenHeight();
  }, []);
  return (
    <div className="info-body" style={{ minHeight: heightDeviceScreen }}>
      <h2 className="title-page">Contact Us To Get More Info</h2>
      <div className="container position-absolute top-50 start-50 translate-middle">
        <a className="email-link" href="mailto:triviacoin@protonmail.com">
          <h4>Trivia Coin Email</h4>
        </a>
        <a className="email-txt" href="mailto:triviacoin@protonmail.com">
          <h4>triviacoin@protonmail.com</h4>
        </a>
      </div>
    </div>
  );
}

export default Info;
