import React from "react";
import LogoTrivia from "../trivialogo.png";
import LogoMGC from "../imgs/mgc-logo.png";
import { useResolvedPath, useMatch, Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footerBottom">
        <div className="projects-component">
          <h1 className="projects-title">Our Projects</h1>
          <div className="projects-block">
            <a
              href="https://manhattangoldcoin.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-footer"
            >
              <img
                src={LogoMGC}
                alt="ManhattanGoldCoin Logo"
                className="mgc-logo"
              />
              <h4 title="projects-text">ManhattanGold Coin</h4>
            </a>
            <CustomLink
              onClick={() => window.scroll(0, 0)}
              to="/"
              className="link-footer"
            >
              <img
                src={LogoTrivia}
                alt="ManhattanGoldCoin Logo"
                className="trivia-logo"
              />
              <h4 title="projects-text">Trivia Coin</h4>
            </CustomLink>
          </div>
        </div>
        <h5 className="footer-paragraph">© 2018-2022 | Triviacoin.com</h5>
      </footer>
    </>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
