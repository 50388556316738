import React, { useEffect } from "react";

export default function RightAlert(props, rightAlert, setRightAlert) {
  useEffect(() => {
    setTimeout(() => {
      props.setRightAlert(false);
    }, props.delay);
  }, [rightAlert]);

  return rightAlert ? <div>{props.children}</div> : <div />;
}
