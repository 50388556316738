import React from "react";
import { Container, Card, Button } from "react-bootstrap";

export default function Results({ createMarkup, score, totalQuestion }) {
  const startAgain = () => {
    console.log("clicked");
    window.location.reload(true);
  };
  return (
    <div className="result-container">
      <Container>
        <Card className="card-big">
          <Card.Body>
            <div className="final-score">
              <h4>
                <span className="bold-text-result">
                  <b>Congratulations! </b>
                </span>
                You Answered
                <span className="bold-text-result">
                  <b> {score} </b>
                </span>
                Questions Right Out Of
                <span className="bold-text-result">
                  <b> {totalQuestion} </b>
                </span>
                !
              </h4>
            </div>

            <Button
              className="play-again"
              size="large"
              variant="outline-light"
              onClick={() => startAgain()}
            >
              PLAY AGAIN
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
