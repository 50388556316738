import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Card, Button, Alert } from "react-bootstrap";
import axios from "axios";
import "../App.css";
import Results from "./Results";
import RightAlert from "../components/RightAlert";
import WrongAlert from "../components/WrongAlert";

export const createMarkup = (text) => {
  return { __html: text };
};
const QuizCategory = () => {
  const [quizData, setquizData] = useState([]);
  const [category, setCategory] = useState([]);
  const [question, setQuestion] = useState([]);
  const [options, setOptions] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [correctAnswer, setCorrectAnswer] = useState();
  const [score, setScore] = useState(0);
  const [prevSelectedElement, setPrevSelectedElement] = useState();
  const [quizState, setQuizState] = useState("start");
  const [showAlert, setShowAlert] = useState(false);
  const [rightAlert, setRightAlert] = useState(false);
  const [wrongAlert, setWrongAlert] = useState(false);
  const [prevAnswer, setPrevAnswer] = useState();
  const totalQuestion = 10;

  const fetchQuizData = async () => {
    const { data } = await axios.get("https://opentdb.com/api.php?amount=10");
    setQuestionsData(data);
    setCategory(data.results[questionNumber].category);
    setQuestion(data.results[questionNumber].question);
    setCorrectAnswer(data.results[questionNumber].correct_answer);

    const formattedData = data.results.map((category) => {
      const incorrectAnswersIndexes = category.incorrect_answers.length;
      const randomIndexes = Math.random() * (incorrectAnswersIndexes - 0) + 0;
      category.incorrect_answers.splice(
        randomIndexes,
        0,
        category.correct_answer
      );
      return {
        ...category,
        answers: category.incorrect_answers,
      };
    });

    setquizData(formattedData);
    setOptions(formattedData[questionNumber].answers);
  };

  useEffect(() => {
    fetchQuizData();
  }, []);
  useMemo(() => {
    if (questionNumber >= 1 && questionNumber < 10) {
      setQuestion(questionsData.results[questionNumber].question);
      setCategory(questionsData.results[questionNumber].category);
      setOptions(quizData[questionNumber].answers);
      setSelectedAnswer();
      setCorrectAnswer(questionsData.results[questionNumber].correct_answer);
      setShowAlert(false);
    } else if (questionNumber === 10) {
      setQuizState("results");
    }
  }, [questionNumber]);

  const selectAnswer = (e, answer) => {
    setSelectedAnswer(answer);

    const optionSelected = "options-selected";
    const newSelectedElement = e.target;

    if (newSelectedElement.classList.value === optionSelected) {
      newSelectedElement.classList.replace("options-selected", "options");
      setPrevSelectedElement(newSelectedElement);
    } else if (prevSelectedElement) {
      prevSelectedElement.classList.replace("options-selected", "options");
      newSelectedElement.classList.replace("options", "options-selected");
      setPrevSelectedElement(newSelectedElement);
    } else {
      newSelectedElement.classList.replace("options", "options-selected");
      setPrevSelectedElement(newSelectedElement);
    }
  };
  const checkAnswer = () => {
    if (selectedAnswer === correctAnswer && questionNumber < 10) {
      setRightAlert(true);
      setPrevAnswer(correctAnswer);
      setScore(score + 1);
      setQuestionNumber(questionNumber + 1);
      setQuestion(questionsData.results[questionNumber].question);
      setCategory(questionsData.results[questionNumber].category);
      setOptions(quizData[questionNumber].answers);
      setSelectedAnswer();
      setCorrectAnswer(questionsData.results[questionNumber].correct_answer);
      setShowAlert(false);
    } else if (
      selectedAnswer !== undefined &&
      selectedAnswer !== correctAnswer &&
      questionNumber < 10
    ) {
      setWrongAlert(true);
      setPrevAnswer(correctAnswer);
      setQuestionNumber(questionNumber + 1);
      setQuestion(questionsData.results[questionNumber].question);
      setCategory(questionsData.results[questionNumber].category);
      setOptions(quizData[questionNumber].answers);
      setSelectedAnswer();
      setCorrectAnswer(questionsData.results[questionNumber].correct_answer);
      setShowAlert(false);
    } else if (
      selectedAnswer !== undefined &&
      selectedAnswer === correctAnswer &&
      questionNumber === 10
    ) {
      setRightAlert(true);
      setPrevAnswer(correctAnswer);
      setScore(score + 1);
      setQuizState("results");
      setShowAlert(false);
    } else if (
      selectedAnswer !== undefined &&
      selectedAnswer !== correctAnswer &&
      questionNumber === 10
    ) {
      setWrongAlert(true);
      setPrevAnswer(correctAnswer);
      setShowAlert(false);
      setQuizState("results");
    } else {
      setShowAlert(true);
    }
  };
  return (
    <div>
      <Container>
        <div className="title-text">
          <h1 className="trivia-text">Trivia</h1>
          <h1 className="coin-text"> Coin</h1>
          <h1 className="game-text">Game</h1>
        </div>

        <Row>
          {quizState === "start" ? (
            <>
              <Card className="card-big">
                <Card.Title>
                  <div
                    className="category"
                    dangerouslySetInnerHTML={createMarkup(category)}
                  ></div>
                </Card.Title>
                <Card.Body>
                  <div className="score">
                    <p
                      dangerouslySetInnerHTML={createMarkup(score)}
                      className="correct-score"
                    ></p>
                    /
                    <p
                      className="total-question"
                      dangerouslySetInnerHTML={createMarkup(totalQuestion)}
                    ></p>
                  </div>
                  <div
                    className="question"
                    dangerouslySetInnerHTML={createMarkup(question)}
                  ></div>
                  {options.map((answer) => (
                    <div
                      onClick={(e) => selectAnswer(e, answer)}
                      key={answer}
                      value={answer}
                    >
                      <li
                        className="options"
                        dangerouslySetInnerHTML={createMarkup(answer)}
                      ></li>
                    </div>
                  ))}

                  {showAlert === true ? (
                    <>
                      <Alert
                        variant="light"
                        onClose={() => setShowAlert(false)}
                        dismissible
                      >
                        <Alert.Heading>Please choose an answer!</Alert.Heading>
                      </Alert>
                    </>
                  ) : (
                    <div className="alert-component"></div>
                  )}
                  {rightAlert === true ? (
                    <>
                      <RightAlert
                        delay="1500"
                        rightAlert={rightAlert}
                        setRightAlert={setRightAlert}
                      >
                        <Alert
                          variant="success"
                          onClose={() => setRightAlert(false)}
                          dismissible
                        >
                          <Alert.Heading>That's Right, Good Job!</Alert.Heading>
                        </Alert>
                      </RightAlert>
                    </>
                  ) : (
                    <div className="alert-component"></div>
                  )}
                  {wrongAlert === true ? (
                    <>
                      <WrongAlert
                        delay="1500"
                        rightAlert={rightAlert}
                        setWrongAlert={setWrongAlert}
                      >
                        <Alert
                          variant="danger"
                          onClose={() => setWrongAlert(false)}
                          dismissible
                        >
                          <Alert.Heading>
                            The Right Answer is <b>{prevAnswer}</b>. You Got the
                            Next One!
                          </Alert.Heading>
                        </Alert>
                      </WrongAlert>
                    </>
                  ) : (
                    <div className="alert-component"></div>
                  )}
                  <Button
                    className="check-btn"
                    size="large"
                    variant="outline-light"
                    onClick={() => checkAnswer()}
                  >
                    CHECK ANSWER
                  </Button>
                </Card.Body>
              </Card>
            </>
          ) : (
            <Results
              createMarkup={createMarkup}
              score={score}
              totalQuestion={totalQuestion}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default QuizCategory;
