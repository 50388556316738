import React from "react";
import "./App.css";
import NavBar from "./components/NavBarComponent";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import DownloadView from "./views/DownloadView";
import Info from "./views/Info";
import TermsView from "./views/TermsView";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <NavBar className="navigation" />
      <div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/download" element={<DownloadView />}></Route>
          <Route path="/info" element={<Info />}></Route>
          <Route path="/terms" element={<TermsView />}></Route>
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
