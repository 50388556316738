import React, { useEffect } from "react";

export default function WrongAlert(props, wrongAlert, setWrongAlert) {
  useEffect(() => {
    setTimeout(() => {
      props.setWrongAlert(false);
    }, props.delay);
  }, [wrongAlert]);

  return wrongAlert ? <div>{props.children}</div> : <div />;
}
