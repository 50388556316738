import React from "react";
import "../App.css";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import QuizCategory from "./QuizCategory";

function Home() {
  const popovertop1 = (
    <Popover
      className="popover-card1"
      id="popover-positioned-top"
      title="bitcoin.com"
    >
      <h4>bitcoin.com</h4>
      <p>
        Morgan Stanley: Cryptocurrencies Here to Stay as Serious Asset Class,
        Bitcoin Making progress to Replace Dollar
      </p>
    </Popover>
  );
  const popovertop2 = (
    <Popover
      className="popover-card2"
      id="popover-positioned-top"
      title="bitcoin.com"
    >
      <h4>bitcoin.com</h4>
      <p>
        Sharma explained that one reason "is growing distrust in fiat
        currencies, thans to massive money printing by central banks."
      </p>
    </Popover>
  );
  const popoverbottom1 = (
    <Popover
      className="popover-card3"
      id="popover-positioned-bottom"
      title="bitcoin.com"
    >
      <h4>bitcoin.com</h4>
      <p>
        Morgan Stanley's head of emerging markets and chief global strategist,
        Ruchir Sharma, published a report entitled "Why Crypto Is Coming Out of
        the Shadows" on the Morgan Stanley website last week. "Despite the
        jitters natural in a global pandemic, cryptocurrencies are rapidly
        gaining popular supports as alternatives to gold (a store of value) and
        the dollar (as a means of payment), "he began. The strategist
        ellaborated:{" "}
      </p>
      <h6>
        "We see fundamental reasons to believe that - regardless of where the
        price of bitcoin goes next - cryptocurrencies are here to stay as a
        serious asset class."
      </h6>
    </Popover>
  );
  const popoverbottom2 = (
    <Popover
      className="popover-card4"
      id="popover-positioned-bottom"
      title="bitcoin.com"
    >
      <h4>bitcoin.com</h4>
      <p>
        Another reason is "generational," as young people view cryptocurrency as
        an improvement over metal coins. He continued: "The worst knock on
        crptocurrency as a sotre of value is its volatility of bitcoin, even
        during the pandemic."
      </p>
    </Popover>
  );

  return (
    <div>
      <div className="home-slideshow">
        <div className="title-slideshow">
          <a href="/">
            <h1 className="trivia-title">TRIVIA</h1>
          </a>
        </div>

        <div className="coin-title-element">
          <h1 className="coin-title">C</h1>
          <div id="circle">
            <OverlayTrigger
              className="card-overlay"
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popovertop1}
            >
              <div id="q1" className="quarter"></div>
            </OverlayTrigger>
            <OverlayTrigger
              className="card-overlay"
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popovertop2}
            >
              <div id="q2" className="quarter"></div>
            </OverlayTrigger>
            <OverlayTrigger
              className="card-overlay"
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popoverbottom1}
            >
              <div id="q3" className="quarter"></div>
            </OverlayTrigger>
            <OverlayTrigger
              className="card-overlay"
              trigger="click"
              rootClose={true}
              placement="auto"
              overlay={popoverbottom2}
            >
              <div id="q4" className="quarter"></div>
            </OverlayTrigger>
          </div>
          <h1 className="coin-title">IN</h1>
        </div>
        <Button
          href="#quizGame"
          className="btn-slideshow"
          size="lg"
          variant="outline-light"
        >
          PLAY NOW
        </Button>
        <h4 className="title-slideshow">Play to Earn Coming Soon!</h4>
      </div>
      <div className="app-body" id="quizGame">
        <QuizCategory />
      </div>
    </div>
  );
}

export default Home;
