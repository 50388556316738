import React, { useEffect, useState } from "react";
import "../App.css";

function TermsView() {
  const [heightDeviceScreen, setHeightDeviceScreen] = useState();
  const getScreenHeight = () => {
    const heightDevice = window.screen.height;
    setHeightDeviceScreen(heightDevice + "px");
  };
  useEffect(() => {
    getScreenHeight();
  }, []);
  return (
    <div className="app-body" style={{ minHeight: heightDeviceScreen }}>
      <h2 className="terms-service-tittle">Triviacoin.me Terms of Use</h2>
      <section className="terms-service">
        <p>
          Please read the whole text of the Terms of use (the “Terms”) for your
          use of www.triviacoin.me (the “site”, the “website”, “our website”)
          together with the additional documents that include information for
          TVC and our organization. These Terms constitute a legally binding
          agreement between you (the “User/s”) and Triviacoin (“We”, “us”), and
          govern your access to and use of Triviacoin.me, including any content,
          functionality and services offered on or through Triviacoin.me. It is
          important to take the time to read them carefully.
        </p>
        <p>
          If you DO NOT agree to these terms, we urge you not to enter, connect,
          access or use the site and/or the services it provides you.
        </p>
        <p>
          By entering to, connecting to, accessing or using Triviacoin.me you
          acknowledge that you have read and understood the following Terms of
          use and you agree to be bound by them and to comply with all
          applicable laws and regulations regarding your use of
          www.Triviacoin.me. You acknowledge that these Terms constitute a
          binding and enforceable legal contract between Triviacoin.me and you.
        </p>
        <p>
          By using the Website, you represent and warrant that you: (i) are 18
          years of age or older; (ii) are not barred to use the Website under
          any applicable law; (iii) are using the Website only for your own
          personal use.
        </p>
        <p>
          If you DO NOT meet these requirements, you must not access or use the
          Website.
        </p>
        <p>
          This site provides its user/s with information about the technology,
          idea and purpose of Triviacoin as a cryptocurrency, about its further
          development, price, news and analysis, and also with an access to the
          Triviacoin forum.
        </p>
        <p>
          The content on the Site, including any information, material and data
          available on www.Triviacoin.me, including any forecasts, analysis or
          other financial information published, and including certain
          information which is proprietary of Triviacoin.me (collectively, the
          “Content“) is made available for educational and research purposes
          only. Triviacoin
        </p>
        <p>
          The website offers to its user/s a subscribe form. By completing the
          form you expressly agree to receive content related to Triviacoin
        </p>
        <p>
          Any person wishing to publish, re-quote or rely on material contained
          in the forum or on the site is required to obtain prior permission
          from Triviacoin for each particular case or to indicate clearly at the
          beginning of the material used, that the same is taken from the forum
          or the website of Triviacoin whose rights are owned by the
          organization.
        </p>
        <p>
          Please note that without the express written consent of Triviacoin you
          are allowed to post only quotes from the forum or site materials, but
          not directly and completely copy the same.
        </p>
        <p>
          There are certain conducts which are strictly prohibited on the Site.
          Please read the following restrictions carefully. Your failure to
          comply with the provisions set forth may result herein (at
          Triviacoin's sole discretion) in the termination of your access to the
          Site and may also expose you to civil and/or criminal liability.
        </p>
        <p>
          You may not, whether by yourself or anyone on your behalf: (i) copy,
          modify, adapt, translate, reverse engineer, decompile, or disassemble
          any portion of the Content at this Site and/or the Service, including
          without limitation any Java applets associated with the Service, in
          any way or publicly display, perform, or distribute them; (ii) make
          any use of the Content on any other website or networked computer
          environment for any purpose, or replicate or copy the Content without
          the Triviacoin Organization’s prior written consent; (iii) create a
          browser or border environment around Triviacoin Content (no frames or
          inline linking); (iv) interfere with or violate any other Site
          visitor’s or user’s right to privacy or other rights, or harvest or
          collect personally identifiable information about visitors or users of
          this Site without their express consent, including using any robot,
          spider, site search or retrieval application, or other manual or
          automatic device or process to retrieve, index, or data-mine; (v)
          defame, abuse, harass, stalk, threaten, or otherwise violate the legal
          rights of others; criticism towards institutions, businesses, and
          private individuals is permitted, but only if it is justified and in a
          non-offensive style – what style is insulting decides the moderator
          and his decision is not subject to discussion; (vi) transmit or
          otherwise make available in connection with this Site any virus, worm,
          Trojan Horse, time bomb, web bug, spyware, or any other computer code,
          file, or program that may or is intended to damage or hijack the
          operation of any hardware, software, or telecommunications equipment,
          or any other actually or potentially harmful, disruptive, or invasive
          code or component; (vii) interfere with or disrupt the operation of
          this Site, or the servers or networks that host this Site or make this
          Site available, or disobey any requirements, procedures, policies, or
          regulations of such servers or networks; (viii) sell, license, or
          exploit for any commercial purposes any use of or access to the
          Content or this Site; (ix) frame or mirror any part of this Site
          without Triviacoin's prior express written authorization; (x) create a
          database by systematically downloading and storing all or any of the
          Content from this Site; (xi) forward any data generated from this Site
          without the prior written consent of Triviacoin or (xii) use the Site,
          the services provided, the content for non-personal or commercial
          purposes without Triviacoin express prior written consent; or (xv)
          infringe or violate any of the Terms.
        </p>
        <p>
          We cannot and do not guarantee or warrant that files available for
          downloading from the internet or the Website will be free of viruses
          or other destructive code. All users are responsible for their
          anti-virus protection and accuracy of data input and output, and for
          maintaining means external to our site for any reconstruction of any
          lost data.
        </p>
        <p>
          All messages in the forum of Triviacoin, as well as the content
          published on the site, express only the personal opinion of their
          respective authors and not of the administrators, moderators or
          webmaster of http://www.Triviacoin.me
        </p>
        <p>
          Therefore,Triviacoin, as well as the administrators, moderators or the
          webmaster of the forum, are not responsible for the content of the
          opinions expressed and/or the materials published in the forum, as
          well as for the contents of the articles published on
          www.Triviacoin.me
        </p>
        <p>
          All users should bear in mind the fact that the European Law, and in
          particular REGULATION (EU) No 596/2014.
        </p>
        <p>
          Triviacoin reserves its right to notify and cooperate fully with the
          competent state authorities in the European Union, including its
          Member States Financial Supervision Commission, the Prosecutor’s
          Office, the Investigation Service and the police authorities, about
          any material that infringes the legislation of any Member State and /
          or the law of the European Union.
        </p>
        <p>
          The webmaster, administrator and moderators of the site cannot be held
          responsible for any hacker attacks on Triviacoin.me.
        </p>
        <p>
          This website and forum system uses analytic tools that store anonymous
          information with the purpose to propose relevant user content and
          improve user experience.
        </p>
        <p>
          The Site, the Service, the Content, and any and all intellectual
          property rights pertaining thereto, including, but not limited to,
          inventions, patents and patent applications, trademarks, trade names,
          logos, copyrightable materials, graphics, text, images, designs
          (including the “look and feel” of the Site), specifications, methods,
          procedures, information, know-how, algorithms, data, technical data,
          interactive features, source and object code, files, interface and
          trade secrets, whether or not registered or capable of being
          registered (collectively, “Intellectual Property“), are owned and/or
          licensed to Triviacoin.
        </p>
        <p>
          (organization) and/or any of its service providers, and subject to
          copyright and other applicable intellectual property rights under
          European laws, foreign laws and international conventions. You may not
          copy, distribute, display, execute publicly, make available to the
          public, reduce to human readable form, decompile, disassemble, adapt,
          sublicense, make any commercial use, sell, rent, lend, process,
          compile, reverse engineer, combine with other software, translate,
          modify or create derivative works of any material that is subject to
          Triviacoin proprietary rights including but not limited to, any Java
          applets associated with the service, including Triviacoin Intellectual
          Property, either by yourself or by anyone on your behalf, in any way
          or by any means, unless expressly permitted in the Terms.
        </p>
        <p>
          You are hereby prohibited from removing or deleting any and all
          copyright notices, restrictions and signs indicating proprietary
          rights, including copyright mark [©] or trademark [® or ™] contained
          in or accompanying the Content, and you represent and warrant that you
          will abide by all applicable laws in this respect.
        </p>
        <p>
          The Site and/or Service availability and functionality depend on
          various factors, such as communication networks. Triviacoin does not
          warrant or guarantee that the Site and/or Service will operate and/or
          be available at all times without disruption or interruption, or that
          it will be error-free. All users are responsible for: (i) making all
          arrangements necessary for them to have access to the Website; (ii)
          ensuring that all persons who access the Website through their
          internet connection are aware of these Terms of Use and comply with
          them.
        </p>
        <p>
          The owner of the Website is based in EU. We make no claims that the
          Website or any of its content is accessible or appropriate outside of
          the EU. If you access the Website from outside EU, you do so on your
          own initiative and are responsible for compliance with local laws.
        </p>
        <p>
          Triviacoin reserves the right to modify, correct, amend, enhance,
          improve, make any other changes to, or discontinue, temporarily or
          permanently this Website, and any service or content we provide on the
          Website, at any time and without notice. You agree that we will not be
          responsible if for any reason all or any part of the Website is
          unavailable at any time or for any period for you or any third party.
          If necessary, we may restrict access to some parts of the Website, or
          the entire Website, to all or some of its users.
        </p>
        <p>
          You agree and acknowledge that you have the sole and ultimate
          responsibility for decisions (including but not limited to any
          financial and/or tax decisions or actions taken as a result, through,
          in conjunction with, or by the use of Triviacoin.
        </p>
        <p>
          In no event shall Triviacoin or its service providers be liable for
          any damages whatsoever, including, but not limited to, direct,
          indirect, special, incidental or Triviacoin.
        </p>
        <p>
          Consequential damages of any kind, whether in an action of contract,
          negligence or other tortious actions resulting from or arising out of
          your use of the site and/or service and/or the content, any decision
          made or action taken or not taken in reliance on the content and any
          and all information which derives from the use of the site and/or
          service , failure of the site and/or service to perform as represented
          or expected, loss of goodwill or profits, the performance or failure
          of Triviacoin or it service providers to perform under these terms,
          any other act or omission of Triviacoin its service providers by any
          other cause whatsoever; or based upon breach of warranty, breach of
          contract, negligence, strict liability, or any other legal theory,
          regardless of whether Triviacoin or its service providers have been
          advised of the possibility of such damages.
        </p>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. Triviacoin has no control over the
          contents of those sites or resources, and is not responsible for them
          or for any loss or damage that Triviacoin may arise from your use of
          them. If you decide to access any of the third party websites linked
          to this Website, you do so entirely at your own risk and subject to
          the terms of use for such websites.
        </p>
        <p>
          Triviacoin reserves the right to update these Terms of use in our sole
          discretion and without any notice. All changes are effective
          immediately when published. Your continued use of the Website
          following the publishing of the revised Terms of use means that you
          accept and agree to be bound by these changes.
        </p>
        <p>
          All matters relating to the Website and these Terms of use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the EU without
          giving effect to any choice or conflict of law provision or rule.
        </p>
        <p>
          Any legal dispute, action or proceeding arising out of, or related to,
          these Terms of use or the Website shall be instituted exclusively
          before the Arbitration Court (or “Tribunal”) at the Bulgarian Chamber
          of Commerce and Industry. We retain the right to bring any suit,
          action or proceeding against you for breach of these Terms of Use in
          your country of residence or any other relevant country You waive any
          and all objections to the exercise of jurisdiction over you by
        </p>
      </section>
      <p className="rights-reserved">All Righst Reserved to TriviaCoin</p>
    </div>
  );
}

export default TermsView;
