import React, { useState } from "react";
import Navbar from "react-bootstrap/NavBar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import trivialogo from "../trivialogo.png";
import "../App.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function NavBarComponent() {
  const [homeActive, setHomeActive] = useState("home-active-link");
  const [downloadActive, setDownloadActive] = useState("navLink");
  const [infoActive, setInfoActive] = useState("navLink");
  const [termsActive, setTermsActive] = useState("navLink");

  const handleClick = (elementClicked) => {
    if (elementClicked === "home") {
      setDownloadActive("navLink");
      setInfoActive("navLink");
      setTermsActive("navLink");
      setHomeActive("home-active-link");
    } else if (elementClicked === "download") {
      setDownloadActive("download-active-link");
      setInfoActive("navLink");
      setTermsActive("navLink");
      setHomeActive("navLink");
    } else if (elementClicked === "terms") {
      setDownloadActive("navLink");
      setInfoActive("navLink");
      setTermsActive("terms-active-link");
      setHomeActive("navLink");
    } else if (elementClicked === "info") {
      setDownloadActive("navLink");
      setInfoActive("info-active-link");
      setTermsActive("navLink");
      setHomeActive("navLink");
    }
  };
  return (
    <div className="customNavbar">
      <Navbar
        collapseOnSelect
        className="customNavbar"
        variant="dark"
        expand="lg"
      >
        <Container className="customNavbar">
          <Navbar.Brand>
            <CustomLink className="logo-navbar" to="/">
              <img
                src={trivialogo}
                width="30"
                height="10"
                className="navbar-img d-inline-block align-top"
                alt="Trivia Coin logo"
              />
            </CustomLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="customNavbar me-auto">
              <CustomLink
                to="/"
                onClick={() => handleClick("home")}
                className={homeActive}
              >
                Home
              </CustomLink>
              <a
                className="navLink"
                href="https://explorer.triviacoin.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Explorer
              </a>
              <CustomLink
                to="/download"
                onClick={() => handleClick("download")}
                className={downloadActive}
              >
                Download
              </CustomLink>
              <CustomLink
                to="/info"
                onClick={() => handleClick("info")}
                className={infoActive}
              >
                Info
              </CustomLink>
              <CustomLink
                to="/terms"
                onClick={() => handleClick("terms")}
                className={termsActive}
              >
                Terms of Service
              </CustomLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
